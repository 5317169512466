<template>
  <div>
    <b-row>
      <b-col cols="9">
        <info />
      </b-col>
      <b-col cols="3" />
    </b-row>
    <b-row>
      <b-col cols="12">
        <vendor />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {BRow, BCol} from 'bootstrap-vue'

import Info from './include/Info.vue'
import Vendor from './include/IndexVendor.vue'

export default {
  components: {
    BRow,
    BCol,
    Info,
    Vendor
  },
}
</script>
