<template>
  <b-row cols="12">
    <b-col cols="5">
      <div class="user-avatar-section">
        <div class="d-flex justify-content-start">
          <img 
            :src="objectView('photo') != '-' ? objectView('photo') : require('@/assets/images/avatars/logo_Avatar.png')" 
            width="104"
            height="104"
            class="img-fluid rounded mr-1" 
          >
          <div class="d-flex flex-column ms-1">
            <div class="user-info mb-1">
              <div 
                v-for="(field, index) in fieldsTitle"
                :key="index"
              >
                <strong v-if="field.is_title"><h4>{{ objectView(field.key) }}</h4></strong>
                <p
                  v-else-if="field.type == 'span'"
                  class="badge mr-1 "
                  :class="objectView(field.key) == 'true'?'bg-primary':'bg-warning'"
                >
                  {{ objectView(field.key) == 'true'?field.data.true:field.data.false }}
                </p>
                <p
                  v-else-if="field.type == 'spankey'"
                  class="badge mr-1 "
                  :class="objectView(field.key) && objectView(field.key) == '1'?'bg-primary':objectView(field.key) && objectView(field.key) == '2'?'bg-danger':'bg-warning'"
                >
                  {{ objectView(field.key)>0?field.data[objectView(field.key)]:'Pengajuan' }}
                </p>
                <span
                  v-else
                  class="card-text mr-1"
                >{{ objectView(field.key) }}</span>
              </div>
            </div>
          </div>
        </div>
        <br>
        <slot name="infodetail" />
      </div>
    </b-col>
    <b-col cols="7">
      <b-col
        v-for="(field, index) in fieldsContent"
        :key="index"
        cols="12"
      >
        <!-- with helper text -->
        <form-label
          :label="field.label"
          :content="objectView(field.key)"
          :type="field.type"
          :icon="field.icon"
          :data-content="field.data"
          :format-date="field.format"
        />
      </b-col>
    </b-col>
    <b-col
      v-if="isedit || isdelete || isAction"
      cols="12"
    >
      <hr>
      
      <b-button
        v-if="isedit"
        class="mr-1"
        size="sm"
        variant="primary"
        type="button"
        @click.prevent="editForm"
      >
        <span>Edit</span>
      </b-button>
      <b-button
        v-if="isdelete"
        class="mr-1"
        size="sm"
        variant="danger"
        type="button"
        @click.prevent="confirmDelete"
      >
        
        <span>Hapus</span>
      </b-button>
      <slot name="buttonaction" />

    </b-col>
  </b-row>
</template>

<script>
import {BRow, BCol, BButton} from 'bootstrap-vue'
import FormLabel from '@/views/base/form/FormLabel.vue'

export default {
  components: {
    BRow,
    BCol,
    FormLabel,
    BButton
  },
  props: {
    geturl: {
      type: String,
      default: '',
    },
    baseroute: {
      type: String,
      default: '',
    },
    fieldsTitle: {
      type: Array,
      default() {
        return []
      },
    },
    fieldsContent: {
      type: Array,
      default() {
        return []
      },
    },
    isedit: {
      type: Boolean,
      default: true
    },
    isdelete: {
      type: Boolean,
      default: true
    },
    isAction: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '',
    },
    dataId:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      model:{},
    }
  },
  beforeMount(){
    this.fetchData()
  },
  methods: {
    fetchData(){
      this.data_id = this.dataId != ''?this.dataId:this.$route.params.id
      this.$http.get(this.geturl+'/'+this.data_id).then(res=>{
        this.model = res.data
        this.$emit("readData", this.model)
      })
    },
    objectView(key){
      let row = ""
      if(key.includes(".")){
        let val = this.model
        for (let i = 0; i < key.split(".").length; i++) {
          if(val == null){
            row = ""
          }else{
            row = val[key.split(".")[i]]
            if(typeof row === 'object')
              val = row
          }
        }
      }else{
        if(key == 'hr' || key == 'hide'){
          row = key
        }else{
          row = this.model[key]
        }
      }

      if(row == "" || row == null)
        row = '-'
      return String(row)
    },
    editForm(){
      this.$router.push({ name: this.baseroute+'-edit',params : { id: this.$route.params.id} })

      // this.dataitem = data.item
      // this.$refs['my-modal-form'].show()
    },
    confirmDelete(){
      const id = this.$route.params.id
      let model = this.model
      model = model.name?model.name:model.number?model.number:model.subject?model.subject:model.number_plate?model.number_plate:''
      this.$bvModal
        .msgBoxConfirm('Are you sure delete '+model+' ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete(this.geturl+'/'+id)
              .then(() => {
                this.$router.push({ name: this.baseroute})
                // this.fetchData()
                  // this.items = data.data
                  // this.recordsTotal = data.recordsTotal
                  // this.loading =false
  
            })
          }
        })
    }
  }
}
</script>
